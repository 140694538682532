import Layout from '@/layout';

const language = {
  path: '/language',
  component: Layout,
  redirect: { name: 'LanguageList' },
  meta: {
    roles: ['admin'],
    title: 'Languages',
    icon: 'fas fa-globe',
  },
  children: [
    {
      path: 'list',
      component: () => import('@/views/language/list'),
      name: 'LanguageList',
      meta: {
        title: 'Languages',
        roles: ['admin'],
        breadcrumb: false,
      },
    },
  ],
};

export default language;
