<template>
  <div
    class="sidebar-logo-container z"
    :class="{ 'collapse': collapse }"
  >
    <transition name="sidebarLogoFade">
      <router-link
        v-if="collapse"
        key="collapse"
        class="sidebar-logo-link"
        to="/"
      >
        <img
          src="@/assets/logo.png"
          class="sidebar-logo"
        />
      </router-link>
      <router-link
        v-else
        key="expand"
        class="sidebar-logo-link"
        to="/"
      >
        <img
          src="@/assets/logo.png"
          class="sidebar-logo"
        />
        <h1 class="sidebar-title">
          {{ title }}
        </h1>
      </router-link>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'SidebarLogo',
    props: {
      collapse: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        title: 'GameZod Admin',
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../../styles/components/_logo.scss";
</style>
