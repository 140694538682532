import Layout from '@/layout';

const shop = {
  path: '/shop',
  component: Layout,
  redirect: { name: 'ShopList' },
  meta: {
    roles: ['admin'],
    title: 'Shops',
    icon: 'fas fa-shopping-cart',
  },
  children: [
    {
      path: 'list',
      component: () => import('@/views/shop/list'),
      name: 'ShopList',
      meta: {
        title: 'Shops',
        roles: ['admin'],
        breadcrumb: false,
      },
    },
    {
      path: 'payments',
      component: () => import('@/views/shop/payments'),
      name: 'PaymentList',
      meta: {
        title: 'Payments',
        roles: ['admin'],
        breadcrumb: true,
      },
      hidden: true,
    },
  ],
};

export default shop;
