import Layout from '@/layout';

const language = {
  path: '/content',
  component: Layout,
  redirect: { name: 'ContentList' },
  meta: {
    roles: ['admin'],
    title: 'Content',
    icon: 'fas fa-language',
  },
  children: [
    {
      path: 'list',
      component: () => import('@/views/content/list'),
      name: 'ContentList',
      meta: {
        title: 'Content',
        roles: ['admin'],
        breadcrumb: false,
      },
    },
  ],
};

export default language;
