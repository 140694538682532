/* ============
 * CKEditor 5
 * ============
 *
 * CKEditor is a modern, feature-rich JavaScript text editor
 * with clean UI and perfect UX. Easily customizable to any use case.
 *
 * https://ckeditor.com
 */

import Vue from 'vue';
import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.use(CKEditor);
