import Layout from '@/layout';

const post = {
  path: '/post',
  component: Layout,
  redirect: { name: 'PostList' },
  meta: {
    roles: ['admin'],
    title: 'Posts',
    icon: 'fas fa-newspaper',
  },
  children: [
    {
      path: 'list',
      component: () => import('@/views/post/list'),
      name: 'PostList',
      meta: {
        title: 'Posts',
        roles: ['admin'],
        breadcrumb: false,
      },
    },
    {
      path: 'categories',
      component: () => import('@/views/post/categories'),
      name: 'CategoryList',
      meta: {
        title: 'Categories',
        roles: ['admin'],
        breadcrumb: true,
      },
      hidden: true,
    },
  ],
};

export default post;
