import Layout from '@/layout';

const game = {
  path: '/game',
  component: Layout,
  redirect: { name: 'GameList' },
  meta: {
    roles: ['admin'],
    title: 'Games',
    icon: 'fas fa-gamepad-alt',
  },
  children: [
    {
      path: 'list',
      component: () => import('@/views/game/list'),
      name: 'GameList',
      meta: {
        title: 'Games',
        roles: ['admin'],
        breadcrumb: false,
      },
    },
  ],
};

export default game;
