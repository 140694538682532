import Layout from '@/layout';
import loggerRouter from './modules/logger';
import accessControlRouter from './modules/accessControl';
import postRouter from './modules/post';
import gameRouter from './modules/game';
import gamePageRouter from './modules/gamePage';
import shopRouter from './modules/shop';
import languageRouter from './modules/language';
import contentRouter from './modules/content';
// import bannerRouter from './modules/banner';
// import localization from './modules/localization';

/**
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will become nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive>
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'icon-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * A base page that does not have permission requirements
 * All roles can be accessed
 */
export const constantRoutes = [
  // redirect
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index'),
      },
    ],
  },
  // login
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true,
    meta: { title: 'Log in' },
  },
  // dashboard
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/dashboard/index'),
        name: 'Dashboard',
        meta: {
          title: 'Dashboard',
          icon: 'fas fa-tachometer-alt-fast',
        },
      },
    ],
  },
  // profile
  {
    path: '/profile',
    component: Layout,
    redirect: '/profile/me',
    children: [
      {
        path: 'me',
        component: () => import('@/views/profile/me'),
        name: 'MyProfile',
        meta: { title: 'My Profile' },
        hidden: true,
      },
    ],
  },
];

/**
 * asyncRoutes
 * The routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
  postRouter,
  gameRouter,
  shopRouter,
  gamePageRouter,
  contentRouter,
  // bannerRouter,
  languageRouter,
  // localization,
  accessControlRouter,
  loggerRouter,
  // A fallback route in case no route is matched
  {
    path: '*',
    component: () => import('@/views/error/404'),
  },
];
