import Layout from '@/layout';

const game = {
  path: '/gamePage',
  component: Layout,
  redirect: { name: 'GamePageList' },
  meta: {
    roles: ['admin'],
    title: 'Game Pages',
    icon: 'fas fa-ballot-check',
  },
  children: [
    {
      path: 'list',
      component: () => import('@/views/gamePage/list'),
      name: 'GamePageList',
      meta: {
        title: 'Game Pages',
        roles: ['admin'],
        breadcrumb: false,
      },
    },
    {
      path: 'images',
      component: () => import('@/views/gamePage/images'),
      name: 'ImageList',
      meta: {
        title: 'Images',
        roles: ['admin'],
        breadcrumb: true,
      },
      hidden: true,
    },
  ],
};

export default game;
